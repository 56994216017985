const initialState = {
    userData: null,
    loading: false,
    error: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_FETCH_REQUEST':
            return { ...state, loading: true, error: null };
        case 'USER_FETCH_SUCCESS':
            return { ...state, loading: false, userData: action.payload, error: null };
        case 'USER_FETCH_FAILURE':
            return { ...state, loading: false, error: action.payload };
        case 'UPDATE_BALANCE':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    balance: action.payload, 
                },
            };
            
        case 'UPDATE_PROFILE_PICTURE':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    defaultProfilePictureUrl: action.payload, 
                },
            };
            case 'UPDATE_NAME':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    fullName: action.payload, 
                },
            };

            case 'UPDATE_SURNAME':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    surname: action.payload, 
                },
            };
        case 'USER_FETCH_CLEAR_ERROR': 
            return { ...state, error: null };
        default:
            return state;
    }
};

export default userReducer;
