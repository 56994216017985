import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, clearError } from '../../redux/actions/userActions';
import './Navbar.scss';
import Error from "../../Pages/Modals/ErrorModal";
import Auth from "../../Pages/Login/Auth";
import url from "../endpoint";

const Navbar = () => {
    const dispatch = useDispatch();
    const { userData, loading, error } = useSelector((state) => state.user);
    const { gameData } = useSelector((state) => state.game);
    const [isAuthOpen, setIsAuthOpen] = useState(false);

 

    useEffect(() => {
        if (!userData) {
            setIsAuthOpen(false); 
        } else {
            setIsAuthOpen(false); 
        }
    }, [userData]);

    useEffect(() => {
        if (!userData) {
            dispatch(fetchUserData());
        }
    }, [dispatch, userData]);


    const handleCloseError = () => {
        dispatch(clearError());
    };

    return (
        <>
            <header className="navbar">
                <div className="navbar-content">
                    {!gameData?.question && (
                        <>
                            <div className="balance">
                                <div className="number">
                                    {loading ? "Loading..." : userData ? `R${userData.balance}` : ''}
                                </div>
                            </div>
                            <div className="account-number">
                                <div className="label">Acc No:</div>
                                <div className="number">{userData?.accountNumber || ''}</div>
                            </div>
                        </>
                    )}

                    {gameData?.question && (
                        <div className="game-container">

                           
                            <div className="game-question">
                                <h1>{gameData.question}</h1>
                            </div>
                        </div>
                    )}
                </div>
            </header>

            

            {error && <Error errorMessage={error} isOpen={true} onClose={handleCloseError} />}

            {isAuthOpen && (
                <Auth
                    isOpen={isAuthOpen}
                    onClose={() => setIsAuthOpen(false)}
                    url={url}
                />
            )}
        </>
    );
};

export default Navbar;
