import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { motion } from "framer-motion";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Riple } from "react-loading-indicators";
import url from "../../components/endpoint";
import * as monaco from "monaco-editor";
import "./Home.scss";
import { useDispatch, useSelector } from 'react-redux';
import { setGameStatusFalse, clearGameData, fetchBuggyCode, fetchWriteFunction, updateNavbarQuestion, fetchOptimizeCode , updateUserCode } from '../../redux/actions/userActions';

const Home = ({ showSidebar, active, closeSidebar }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [joining, setJoining] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const dispatch = useDispatch();

  const { gameData, tryAgain } = useSelector((state) => state.game);
  const editorRef = useRef(null);

  useEffect(() => {
    const handleTryAgain = async () => {
      if (tryAgain) {
        // Dispose of the editor if it's already initialized
        if (editorRef.current) {
          editorRef.current.dispose();
          editorRef.current = null;
        }

        // Proceed with your join logic
        let gameId = +localStorage.getItem("activeGame");
        if (!isNaN(gameId)) {
          await handleJoin(gameId);
        }
      }
    };

    handleTryAgain();
  }, [tryAgain]);

  const handleJoin = async (gameId) => {
    setJoining(true);

    if (gameId === 1) {
      dispatch(fetchBuggyCode(gameId));
      dispatch(updateNavbarQuestion(gameData.question));
      localStorage.setItem('activeGame', gameId);
    }

    if (gameId === 2) {
      dispatch(fetchWriteFunction());
      dispatch(updateNavbarQuestion(gameData.question));
      localStorage.setItem('activeGame', gameId);
    }

    if (gameId === 3) {
      dispatch(fetchOptimizeCode());
      dispatch(updateNavbarQuestion(gameData.question));
      localStorage.setItem('activeGame', gameId);
    }

    setTimeout(() => {
      setJoining(false);
      setShowEditor(true);
    }, 2000);
  };

  const fetchGames = async () => {
    try {
      const response = await axios.get(`${url}/api/Games/competitions`);
      setGames(response.data.competitions);
    } catch (error) {
      console.error("Error fetching games:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  useEffect(() => {
    const getLanguageForEditor = (lang) => {
      const languageMapping = {
        "C#": "csharp",
        "C++": "cpp",
        "JavaScript": "javascript",
        "Java": "java",
        "Python": "python",
      };

      return languageMapping[lang] || "plaintext";
    };

    // Determine the correct language
    const editorLanguage = getLanguageForEditor(gameData.language || gameData.codingLanguage);

    if (showEditor && gameData.buggyCode !== "") {
      if (!editorRef.current) {
        const editor = monaco.editor.create(document.getElementById("editor"), {
          value: gameData.buggyCode || gameData.code,
          language: editorLanguage,
          theme: "vs-dark",
          automaticLayout: true,
        });

        // Set up event listeners
        editor.onKeyDown((e) => {
          const isCtrlOrCmd = e.ctrlKey || e.metaKey;
          if (isCtrlOrCmd && ["KeyZ", "KeyX", "KeyC", "KeyV"].includes(e.code)) {
            e.preventDefault();
          }
        });

        // Disable right-click context menu
        const domNode = editor.getDomNode();
        if (domNode) {
          domNode.addEventListener("contextmenu", (e) => e.preventDefault());
        }

        // Listen for changes in the code
        editor.onDidChangeModelContent((event) => {
          const updatedCode = editor.getValue();
          dispatch(updateUserCode(updatedCode));
          console.log("User entered code: ", updatedCode);
        });

        editorRef.current = editor;
      }
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.dispose();
        editorRef.current = null;
        dispatch(clearGameData());
        dispatch(setGameStatusFalse());
      }
    };
  }, [showEditor, gameData.buggyCode, gameData.language, gameData.codingLanguage , tryAgain]);

  const renderSkeletons = () => [...Array(6)].map((_, index) => (
    <div className="skeleton_card" key={index}>
      <Skeleton height={150} width={250} className="skeleton_image" />
      <Skeleton count={2} />
    </div>
  ));

  const renderGameCards = () => games.map((game) => (
    <motion.div
      key={game.id}
      className="game_card"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <img src={game.image} alt={game.name} className="game_image" />
      <div className="game_info">
        <h3>{game.name}</h3>
        <div className="game-details">
          <div className="game-row">
            <div className="game-item-inline">
              <div className="detail-label">Prize:</div>
              <div className="detail-value">R{game.prizePool}</div>
              <div className="detail-label">Fee:</div>
              <div className="detail-value">R{game.entryFee}</div>
            </div>
          </div>

          <div className="join-now">
            <button className="btn-join-now" onClick={() => handleJoin(game.id)}>
              Play Now
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  ));

  return (
    <div className="home">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="home_container">
        <Navbar showSidebar={showSidebar} />
        <div className="scrollview relative flex items-center justify-center">
          {joining ? (
            <div className="loading-indicator-wrapper">
              <Riple color="#32cd32" size="large" />
            </div>
          ) : loading ? (
            renderSkeletons()
          ) : showEditor ? (
            <div id="editor" className="editor-container"></div>
          ) : (
            renderGameCards()
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
