import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReferralHandler from "./ReferralHandler"; 
import Home from "./Pages/Home/Home";
import Profile from "./Pages/Profile/Profile";
import Deposit from "./Pages/Deposit/Deposit";
import Wallet from "./Pages/wallet/Wallet";
import NotFound from "./Pages/NotFound/PageNotFound";
import Refer from "./Pages/Referral/refer";
import Withdraw from "./Pages/Withdrawal/Withdrawal";
import Verification from "./Pages/Verification/verification";
import Chat from "./Pages/Chat/chatbox";
import Security from "./Pages/Security/security";
import EditProfile from "./Pages/EditProfile/EditProfile";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Provider } from "react-redux"; 
import store from './redux/store'; 

function App() {
    const [active, setActive] = useState("");

    const showSidebar = () => {
        setActive("active");
    };

    const closeSidebar = () => {
        setActive("");
    };


    useEffect(() => {
        const redirectToAuth = () => {
            if (window.location.pathname === "/login") {
                window.location.href = "https://auth.play929.com/login";
            } else if (window.location.pathname === "/signup") {
                window.location.href = "https://auth.play929.com/signup";
            }
        };

        redirectToAuth();
    }, []); 

    return (
        <Provider store={store}> 
            <Router>
                <Routes>
                    <Route path="/" >
                        <Route index element={<Home />} />
                        <Route
                            path="profile"
                            element={
                                <Profile
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="deposit"
                            element={
                                <Deposit
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="dashboard"
                            element={
                                <Home
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="verification"
                            element={
                                <Verification
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="edit-profile"
                            element={
                                <EditProfile
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="security"
                            element={
                                <Security
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="wallets"
                            element={
                                <Wallet
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="chat"
                            element={
                                <Chat
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="withdraw"
                            element={
                                <Withdraw
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route
                            path="refer"
                            element={
                                <Refer
                                    showSidebar={showSidebar}
                                    closeSidebar={closeSidebar}
                                    active={active}
                                />
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Router>
            <Analytics />
            <SpeedInsights />
        </Provider> 
    );
}

export default App;
