import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import "./log.scss";

const Log = ({ show, onClose, serverLogs }) => {
  const [logs, setLogs] = useState([]);

  // Simulating the log update
  useEffect(() => {
    if (serverLogs) {
      setLogs((prevLogs) => [...prevLogs, serverLogs]);
    }
  }, [serverLogs]);

  return (
    <div className={`log-modal ${show ? "show" : ""}`}>
      <div className="log-modal-content">
        <div className="log-header">
          <h3>Validation Logs</h3>
          <IoClose className="close-icon" onClick={onClose} />
        </div>
        <div className="log-body">
          <pre>{logs.join("\n")}</pre>
        </div>
      </div>
    </div>
  );
};

export default Log;
