import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; 
import userReducer from './reducers/userReducer';
import gameReducer from './reducers/gameReducer';

const rootReducer = combineReducers({
  user: userReducer,
  game: gameReducer,
});

const middleware = [thunk];


const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

export default store;
