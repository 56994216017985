import React, { useState } from "react";
import axios from "axios";
import "./Deposit.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import url from "../../components/endpoint";

const Deposit = ({ showSidebar, active, closeSidebar }) => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [depositMethod, setDepositMethod] = useState("");

  const validateAmount = () => {
    if (isNaN(amount) || amount <= 0) {
      toast.error("Invalid amount");
      return false;
    }
    if (amount < 10) {
      toast.error("Minimum amount is R10");
      return false;
    }
    if (amount > 1000) {
      toast.error("Maximum amount is R1000");
      return false;
    }
    return true;
  };

  const handleDeposit = async (method) => {
    if (!validateAmount()) return;

    setLoading(true);
    try {
      const requestBody = { amount: parseFloat(amount) };
      const token = localStorage.getItem("authToken");  // Assume token is stored in localStorage

      const response = await axios.post(`${url}/deposit/${method}`, requestBody, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success("Redirecting to payment page...");
        window.location.href = response.data.paymentLink;
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="deposit">
      <ToastContainer />
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="deposit_container">
        <Navbar showSidebar={showSidebar} />
        <div className="content">
          
            <div className="deposit_form">
              <h2>All Payment Methods</h2>
              <div className="payment-methods">
                <div
                  className="payment-card"
                  onClick={() => setDepositMethod("bank")}
                  aria-label="Select Bank Deposit Method"
                >
                  <img src="https://pp.ekspp.com/images/payments/pm/Online_transfer_ZAR/48/True.svg" alt="Bank Logo" />
                  <div>
                    <h3>Internet Banking</h3>
                    <p>Processing Time: Instant - 30 mins</p>
                    <p>Fee: 0%</p>
                    <p>Limit: R20 - R1000</p>
                  </div>
                </div>
                <div
                  className="payment-card"
                  onClick={() => setDepositMethod("paypal")}
                  aria-label="Select PayPal Deposit Method"
                >
                  <img src="https://www.svgrepo.com/show/452082/paypal.svg" alt="PayPal Logo" />
                  <div>
                    <h3>PayPal</h3>
                    <p>Processing Time: Instant</p>
                    <p>Fee: 2.9% + R0.30</p>
                    <p>Limit: R10 - R1000</p>
                  </div>
                </div>
                <div
                  className="payment-card"
                  onClick={() => setDepositMethod("Skrill")}
                  aria-label="Select Skrill Deposit Method"
                >
                  <img src="https://pp.ekspp.com/images/payments/pm/Skrill/48/True.svg" alt="Skrill Logo" />
                  <div>
                    <h3>Skrill</h3>
                    <p>Processing Time: Instant</p>
                    <p>Fee: 0%</p>
                    <p>Limit: R50 - R5000</p>
                  </div>
                </div>
                <div
                  className="payment-card"
                  onClick={() => setDepositMethod("Binance")}
                  aria-label="Select Binance Deposit Method"
                >
                  <img src="https://pp.ekspp.com/images/payments/pm/Binance/48/True.svg" alt="Binance Logo" />
                  <div>
                    <h3>Binance Pay</h3>
                    <p>Processing Time: Instant</p>
                    <p>Fee: 0.5%</p>
                    <p>Limit: R10 - R10000</p>
                  </div>
                </div>
                <div
                  className="payment-card"
                  onClick={() => setDepositMethod("Neteller")}
                  aria-label="Select Neteller Deposit Method"
                >
                  <img src="https://pp.ekspp.com/images/payments/pm/Neteller/48/True.svg" alt="Neteller Logo" />
                  <div>
                    <h3>Neteller</h3>
                    <p>Processing Time: Instant</p>
                    <p>Fee: 0.5%</p>
                    <p>Limit: R10 - R10000</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
      
      </div>
    </div>
  );
};

export default Deposit;
