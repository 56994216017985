import "./sidebar.scss";
import "../../App.scss";
import { Link } from "react-router-dom";
import { IoHome, IoLogOut, IoSend } from "react-icons/io5";
import { FaUser, FaSpinner } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GameModal from '../../Pages/Modals/GameModal';
import { setGameStatusFalse, clearGameData, tryAgain } from '../../redux/actions/userActions'; 
import url from "../endpoint";
import * as signalR from "@microsoft/signalr";
import Log from "../../Pages/Modals/log";

const Sidebar = ({ active, closeSidebar }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState("home");
  const [timeLeft, setTimeLeft] = useState(0); 
  const [isGameFinished, setIsGameFinished] = useState(false);
  const {status, gameData, userCode} = useSelector((state) => state.game);
  const [showModal, setShowModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
const [serverLogs, setServerLogs] = useState([]);

  const handleTryAgain = () => {
    setShowModal(false);
    dispatch(clearGameData());
    setTimeLeft(0); 
    dispatch(tryAgain());
  };

  // SignalR connection
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    if (status && gameData?.signature && gameData?.signaturePayload) {
      console.log("signature", gameData.signature);

      const connectionInstance = new signalR.HubConnectionBuilder()
        .withUrl(`${url}/gameHub?signature=${gameData.signature}&payload=${gameData.signaturePayload}`)
        .build();

      connectionInstance
        .start()
        .then(() => console.log("Connection established"))
        .catch((err) => console.error("Connection failed: ", err));

      connectionInstance.on("CountdownTimer", (time) => {
        console.log("Time left:", time);
        setTimeLeft(time); 
      });

      connectionInstance.on("CountdownFinished", () => {
        dispatch(setGameStatusFalse());
        dispatch(clearGameData());
        setShowModal(true);
        setIsGameFinished(true); 
      });

      connectionInstance.on("Error", (message) => {
        console.error("Error:", message);
      });

      setConnection(connectionInstance);
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [status, gameData]);

  const handleSubmit = async (userCode) => {
    if (connection) {
      try {
        // Emit user code to the server
        await connection.invoke("ValidateUserCode", userCode);
        
        // Simulate server log updates
        setServerLogs(["Submitting user code...", "Waiting for validation..."]);
        setShowLogModal(true);
      } catch (err) {
        // Handle error and prevent cyclic object issue
        console.error("Error submitting user code:", err);
  
        // Safe logging function to handle cyclic objects
        const safeStringify = (obj) => {
          let cache = [];
          const stringified = JSON.stringify(obj, (key, value) => {
            if (typeof value === "object" && value !== null) {
              if (cache.includes(value)) {
                // Circular reference found, return undefined to avoid it
                return;
              }
              cache.push(value);
            }
            return value;
          });
          cache = null; 
          return stringified;
        };
  
      
        setServerLogs([
          `Error submitting code: ${safeStringify(err)}`,
          "Waiting for validation..."
        ]);
        setShowLogModal(true);
      }
    }
  };
  

// Closing the log modal
const closeLogModal = () => {
  setShowLogModal(false);
};


  const handleLogout = () => {
    setLoading(true);
    localStorage.clear();
    setTimeout(() => {
      setLoading(false);
      window.location.href = "";
      closeSidebar();
    }, 2000);
  };

  const startCountDown = () => {
    if (connection && gameData?.signature && gameData?.signaturePayload) {
      connection
        .invoke("StartGame", gameData.signature, gameData.signaturePayload)
        .then(() => console.log("Game started"))
        .catch((err) => console.error("Error starting game:", err));
    }
  };

  useEffect(() => {
    if (status && connection) {
      startCountDown(); 
    }
  }, [status, connection]);

  // Format time in mm:ss format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <aside className={`sidebar ${active}`}>
      {loading && (
        <div className="overlay">
          <FaSpinner className="loading-spinner" />
          <p className="loading-text">Logging out...</p>
        </div>
      )}
      <div className="top">
        <h3>Play929.com</h3>
        <div className="close_btn" onClick={closeSidebar}>
          &times;
        </div>
      </div>
  
      <div className="middle">
        {status ? (
          <>
            <div
              className={`countdown-timer ${
                timeLeft < 30 ? "red-timer" : "green-timer"
              }`}
            >
              {formatTime(timeLeft)}
            </div>
  
            {isGameFinished && <div className="game-finished-message">Game Over!</div>}
  
            <Link
              className={activeItem === "submit" ? "link active" : "link"}
              onClick={handleSubmit}
            >
              <IoSend className="icon" />
              <span>Submit</span>
            </Link>
          </>
        ) : (
          <>
            <Link
              onClick={() => setActiveItem("home")}
              className={activeItem === "home" ? "link active" : "link"}
              to="/dashboard"
            >
              <IoHome className="icon" />
              <span>Home</span>
            </Link>
  
            <Link
              onClick={() => setActiveItem("wallet")}
              className={activeItem === "wallet" ? "link active" : "link"}
              to="/wallets"
            >
              <GiWallet className="icon" />
              <span>Wallet</span>
            </Link>
  
            <Link className="link" to="/profile">
              <FaUser className="icon" />
              <span>Profile</span>
            </Link>
  
            <Link
              className="link"
              to=""
              onClick={() => {
                closeSidebar();
                handleLogout();
              }}
            >
              <IoLogOut className="icon" />
              <span>Logout</span>
            </Link>
          </>
        )}
      </div>
  
      {showModal && (
        <GameModal
          message="Game Over! Try again?"
          onTryAgain={handleTryAgain}
        />
      )}
      <Log show={showLogModal} onClose={closeLogModal} serverLogs={serverLogs} />;
    </aside>
  );
};  

export default Sidebar;
