import React from "react";
import PropTypes from "prop-types";
import "./gameModal.scss";

const GameModal = ({ message, onTryAgain }) => {
  return (
    <div className="game-modal-overlay">
      <div className="game-modal">
        <div className="game-modal-content">
          <h2 className="game-modal-message">{message}</h2>
          <button className="game-modal-btn" onClick={onTryAgain}>
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

GameModal.propTypes = {
  message: PropTypes.string.isRequired,
  onTryAgain: PropTypes.func.isRequired,
};

export default GameModal;
