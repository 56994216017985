import axios from 'axios';
import url from '../../components/endpoint';



export const fetchUserData = () => async (dispatch) => {
    try {
        dispatch({ type: 'USER_FETCH_REQUEST' });

        const response = await axios.get(`${url}/api/User/data`, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${url}`, 
            },
            
        });

        dispatch({ type: 'USER_FETCH_SUCCESS', payload: response.data });
    } catch (error) {
        console.log(error);
        dispatch({ type: 'USER_FETCH_FAILURE', payload: error.message });
    }
};

export const fetchBuggyCode = (gameId) => async (dispatch) => {
    try {
        dispatch({ type: 'BUGGYCODE_FETCH_REQUEST' });

        const response = await axios.get(`${url}/api/Games/generateBuggyCode/${gameId}`, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${url}`, 
            },
        });

        dispatch({ 
            type: 'BUGGYCODE_FETCH_SUCCESS', 
            payload: { 
              data: response.data, 
              status: true 
            } 
          });
    } catch (error) {
        console.error("Error fetching buggy code:", error);
        dispatch({ type: 'BUGGYCODE_FETCH_FAILURE', payload: error.message });
    }
};

export const fetchOptimizeCode = () => async (dispatch) => {
    try {
        dispatch({ type: 'OPTIMIZE_FETCH_REQUEST' });

        const response = await axios.get(`${url}/api/Games/optimize-algorithm`, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${url}`, 
            },
        });

        dispatch({ 
            type: 'OPTIMIZE_FETCH_SUCCESS', 
            payload: { 
              data: response.data, 
              status: true 
            } 
          });
    } catch (error) {
        console.error("Error fetching  code:", error);
        dispatch({ type: 'OPTIMIZE_FETCH_FAILURE', payload: error.message });
    }
};

export const fetchWriteFunction = () => async (dispatch) => {
    try {
        dispatch({ type: 'WRITE_FETCH_REQUEST' });

        const response = await axios.get(`${url}/api/Games/write-function`, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${url}`, 
            },
        });
 
        dispatch({ 
            type: 'WRITE_FETCH_SUCCESS', 
            payload: { 
              data: response.data, 
              status: true 
            } 
          });
          
    } catch (error) {
        console.error("Error fetching question:", error);
        dispatch({ type: 'WRITE_FETCH_FAILURE', payload: error.message });
    }
};


export const updateBalance = (newBalance) => {
    return {
        type: 'UPDATE_BALANCE',
        payload: newBalance,
    };
};


export const setGameStatusFalse = () => ({
    type: 'SET_GAME_STATUS_FALSE',
  });
  
  export const clearGameData = () => ({
    type: 'CLEAR_GAME_DATA',
  });

  export const tryAgain = () => ({
    type: 'TRY_AGAIN',
  });
  

export const updateNavbarQuestion = (question) => {
    return {
        type: 'UPDATE_NAVBAR_QUESTION',
        payload: question,
    };
};

export const updateUserCode = (code) => {
    return {
        type: 'USER_CODE',
        payload: code,
    };
};

export const updateProfilePicture = (file) => {
    return {
        type: 'UPDATE_PROFILE_PICTURE',
        payload: file,
    };
};

export const updateName = (name) => {
    return {
        type: 'UPDATE_NAME',
        payload: name,
    };
};

export const updateSurName = (surname) => {
    return {
        type: 'UPDATE_SURNAME',
        payload: surname,
    };
};


export const clearError = () => {
    return {
        type: 'USER_FETCH_CLEAR_ERROR',
    };
};
