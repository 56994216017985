import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import './verification.scss';
import url from '../../components/endpoint';

const Verification = ({ showSidebar, active, closeSidebar }) => {
  const [message, setMessage] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const { email } = useSelector((state) => state.user.userData) || {};



  const handleSubmit = async (url, method, data, successMessage, errorMessage) => {
    setLoading(true);
    try {
      const response = await axios({ method, url, data, withCredentials: true, headers: { Authorization: `Bearer ${url}` } });
      setMessage(successMessage);
      return response;
    } catch (error) {
      const errorMsg = error.response?.data?.error || errorMessage;
      toast.error(errorMsg);
      throw error; 
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
  
    if (!email) return toast.error("Email not available.");
    

    toast.promise(
      handleSubmit(
        `${url}/otp/emailVerification`,
        'get',
        { userEmail: email },
        'OTP sent to your email. Please check your inbox.',
        'Failed to generate OTP. Please try again.'
      ),
      {
        pending: "Generating your OTP...",
        success: () => {
          setOtpSent(true);
        }
      }
    );
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    toast.promise(
      handleSubmit(
        `${url}/otp/verifyOTP`,
        'post',
        { userEmail: email, otp },
        'OTP verified successfully. Please upload your bank statement.',
        'Failed to verify OTP.'
      ),
      {
        pending: 'Verifying your email...',
        success: () => {
          setOtpVerified(true);
        }
      }
    );
  };

  const handleSubmitVerification = (e) => {
    e.preventDefault();
    setMessage('Verification submitted successfully!');
  };

  return (
    <div className="verification-wrapper">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="verification-container">
        <Navbar showSidebar={showSidebar} />
        <h2>Verification Required</h2>
        <p>Please verify your email.</p>

        <form
          onSubmit={otpVerified ? handleSubmitVerification : otpSent ? handleVerifyOtp : handleEmailSubmit}
          className="verification-form"
        >
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" value={email || ''} readOnly required placeholder="Enter your email" />
          </div>

          {otpSent && !otpVerified && (
            <div className="form-group">
              <label htmlFor="otp">Enter OTP</label>
              <input type="text" id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} required placeholder="Enter OTP" className="otp-input" />
            </div>
          )}
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Processing...' : otpVerified ? 'Submit Verification' : otpSent ? 'Verify Email' : 'Send OTP'}
          </button>
        </form>

        {message && <div className="verification-message">{message}</div>}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Verification;
